import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  idleState = new Subject<string>();
  idleState$ = this.idleState.asObservable();

  sessionExpiringSubject = new Subject<boolean>();
  sessionExpiring$ = this.sessionExpiringSubject.asObservable();

  constructor(private idle: Idle, private keepalive: Keepalive) {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  configureIdle(
    idleTime: number,
    timeout: number,
    keepaliveInterval: number = 0
  ) {
    this.idle.setIdle(idleTime);
    this.idle.setTimeout(timeout);
    if (keepaliveInterval > 0) {
      this.keepalive.interval(keepaliveInterval);
    }

    this.idle.onIdleStart.subscribe(() => {
      this.sessionExpiringSubject.next(false);
    });

    this.idle.onTimeout.subscribe(() => {
      this.sessionExpiringSubject.next(true);
    });
  }

  startWatching() {
    this.idle.watch();
    this.idleState.next('Started');
  }

  stopWatching() {
    this.idle.stop();
    this.idleState.next('Stopped');
  }

  onIdleEnd() {
    return this.idle.onIdleEnd;
  }

  onTimeout() {
    return this.idle.onTimeout;
  }

  onIdleStart() {
    return this.idle.onIdleStart;
  }
}

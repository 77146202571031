<ng-container *ngIf="showMobileInputScreen">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Activate SMS text message factor</h3>
      <p>
        Mobile phone numbers are stored securely for security verification purposes only. For more information on how Labcorp protects your
        privacy, please see Labcorp's
        <a href="https://www.labcorp.com/hipaa-privacy" target="_blank">Privacy Policy</a>.
      </p>
      <p>
        Select your country code from dropdown list if it is not already displayed. Then, enter your mobile device number and click
        Activate.
      </p>
      <form #f="ngForm" [formGroup]="phoneForm" novalidate>
        <div class="pt-3 d-flex flex-row flex-wrap">
          <div class="pe-0 flex-grow-1 col-lg-6 ps-0">
            <label for="firstName">Enter your Mobile Number</label>
            <ngx-intl-tel-input
              [cssClass]="'customTel'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="false"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [selectFirstCountry]="false"
              [phoneValidation]="true"
              [separateDialCode]="true"
              name="phone"
              formControlName="phone"
              ngDefaultControl
              id="phoneNumberMFA"
            >
            </ngx-intl-tel-input>
            <small class="form-text invalid-feedback" [ngClass]="isValid ? 'd-none' : 'd-block'">
              <i class="material-icons-round icon-sm">error_outline</i>
              <span class="align-self-center ps-1" [innerHTML]="'Please enter a valid mobile number'"></span>
            </small>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer justify-content-start">
    <button
      id="smsActivateMFA"
      type="button"
      class="btn btn-primary"
      [disabled]="isLoading"
      (click)="enroll(f?.form?.value?.phone?.e164Number, f.form.controls['phone'].invalid)"
    >
      Activate
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showOTPForm">
  <div class="modal-body pb-3">
    <div class="custom-modal-body text-center">
      <p>
        You should receive an SMS text message on your mobile device with a verification code. <br />Please enter the verification code that
        you received on your mobile device.
      </p>
      <p *ngIf="!validOTPInput">You have three attempts to enter the correct verification code.</p>
      <patient-ui-activate-form
        name="fieldName"
        ngDefaultControl
        #input="ngModel"
        [invalidInput]="!validOTPInput"
        [(ngModel)]="data"
      ></patient-ui-activate-form>
      <small class="form-text invalid-feedback" [ngClass]="validOTPInput ? 'd-none' : 'd-block'">
        <i class="material-icons-round icon-sm">error_outline</i>
        <span class="align-self-center ps-1" [innerHTML]="'The verification code you entered is incorrect'"></span>
      </small>
    </div>
    <ui-legal-disclaimer
      [addClass]="'mt-3'"
      linkText="Verify"
      termsUrl="https://www.labcorp.com/terms-and-conditions"
      privacyUrl="https://www.labcorp.com/hipaa-privacy"
    ></ui-legal-disclaimer>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      *ngIf="!validOTPInput && resendCount < 3"
      type="button"
      class="btn btn-secondary me-3"
      [disabled]="isLoading"
      (click)="enroll(phoneForm.value.phone.e164Number, false, true)"
    >
      Resend code
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!input.valid || isLoading" (click)="activateSMSFactor(data)">
      {{ validOTPInput ? 'Verify' : 'Retry' }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showFailedAttemptScreen">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <p>
        You have reached the maximum number of incorrect entries. You'll need start over to activate the SMS text message factor.
      </p>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-primary" (click)="handleFailedAttemp()">Done</button>
  </div>
</ng-container>

<div *ngIf="showConfirmationScreen">
  <patient-ui-confirmation-screen factorType="SMS authentication factor."></patient-ui-confirmation-screen>
</div>

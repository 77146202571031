/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

let nextId = 0;

@Component({
  selector: 'ui-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseComponent {
  @Input() type: 'simple' | 'add' = 'simple';
  @Input() header = '';
  @Input() headerButton = 'Add';
  @Input() alertTemplate!: TemplateRef<unknown>;
  @Input() processing!: TemplateRef<unknown>;

  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();

  @Input() completed = false;
  @Output() completedChange = new EventEmitter<boolean>();

  @Input() saveButton = 'Save';
  @Input() saveDisabled = false;

  @Input() insuraceNotListedButton = 'My Insurance Provider is not listed';

  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();
  @Output() insuranceFormNotFoundClicked = new EventEmitter();

  @Input() isProcessing = false;
  @Input() isProviderNotListedSelected = false;
  @Input() validateForm = false;
  @Input() validatePrimaryForm = false;
  @Input() validateSecondaryForm = false;
  @Input() insuranceForm: UntypedFormGroup = new UntypedFormGroup({});

  isMobile = false;
  id = nextId++;

  _isInsuranceNotListedButtonHidden = false;

  constructor() {
    this.checkDeviceSize();
  }

  @Input()
  get isInsuranceNotListedButtonHidden() {
    return this._isInsuranceNotListedButtonHidden;
  }
  set isInsuranceNotListedButtonHidden(value: boolean) {
    this._isInsuranceNotListedButtonHidden = value;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDeviceSize();
  }

  setOpen(newValue: boolean) {
    this.open = newValue;
    this.openChange.emit(this.open);
  }

  setCompleted(newValue: boolean) {
    this.completed = newValue;
    this.completedChange.emit(this.completed);
  }

  toggleOpen() {
    this.setOpen(!this.open);
    if (this.completed) this.setCompleted(false);
  }

  cancelAndClose() {
    if (this.validatePrimaryForm || this.validateSecondaryForm) {
      this.isInsuranceNotListedButtonHidden = false;
    }
    this.setOpen(false);
    if (this.completed) this.setCompleted(false);
    this.cancelClicked.emit();
  }

  selectInsuranceNotListed() {
    this.insuranceFormNotFoundClicked.emit(true);
  }

  saveAndCloseCompleted() {
    this.saveClicked.emit();

    if (this.insuranceForm.valid && this.insuranceForm.touched) {
      this.setOpen(false);
      this.setCompleted(true);
    }
  }

  checkDeviceSize() {
    this.isMobile = window.innerWidth <= 768;
  }
}

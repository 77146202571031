import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbRatingModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AlertComponent } from './alert/alert.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { ChangedFieldsComponent } from './changed-fields/changed-fields.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { CircleComponent } from './circle/circle.component';
import { CollapseComponent } from './collapse/collapse.component';
import { DatePickerHeaderComponent } from './date-picker-header/date-picker-header.component';
import { DropdownFieldsComponent } from './dropdown-fields/dropdown-fields.component';
import { DropdownLinkComponent } from './dropdown-link/dropdown-link.component';
import { FormFieldMsgComponent } from './form-field-msg/form-field-msg.component';
import { LegalDisclaimerComponent } from './legal-disclaimer/legal-disclaimer.component';
import { LocationListItemComponent } from './location-list-item/location-list-item.component';
import { LocationListItemAppointmentsComponent } from './location-list-item-appointments/location-list-item-appointments.component';
import { LocationListItemHeaderComponent } from './location-list-item-header/location-list-item-header.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { MapComponent } from './map/map.component';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { ModalComponent } from './modal/modal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NewModalComponent } from './new-modal/new-modal.component';
import { RegisteredFooterComponent } from './registered-footer/registered-footer.component';
import { SelectComponent } from './select/select.component';
import { StepperComponent } from './stepper/stepper.component';

@NgModule({
  declarations: [
    AlertComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CardComponent,
    ChangedFieldsComponent,
    CheckboxGroupComponent,
    CircleComponent,
    CollapseComponent,
    DatePickerHeaderComponent,
    DropdownFieldsComponent,
    DropdownLinkComponent,
    FormFieldMsgComponent,
    LegalDisclaimerComponent,
    LocationListItemAppointmentsComponent,
    LocationListItemHeaderComponent,
    LocationListItemComponent,
    MapMarkerComponent,
    MapComponent,
    ModalComponent,
    NavbarComponent,
    RegisteredFooterComponent,
    SelectComponent,
    StepperComponent,
    NewModalComponent,
    LoginFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbRatingModule,
    NgbTimepickerModule,
    NgbToastModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    AlertComponent,
    BreadcrumbComponent,
    ButtonComponent,
    CardComponent,
    ChangedFieldsComponent,
    CheckboxGroupComponent,
    CircleComponent,
    CollapseComponent,
    DatePickerHeaderComponent,
    DropdownFieldsComponent,
    DropdownLinkComponent,
    FormFieldMsgComponent,
    LocationListItemAppointmentsComponent,
    LocationListItemHeaderComponent,
    LocationListItemComponent,
    LegalDisclaimerComponent,
    MapMarkerComponent,
    MapComponent,
    ModalComponent,
    NavbarComponent,
    RegisteredFooterComponent,
    SelectComponent,
    StepperComponent,
    NewModalComponent,
    LoginFormComponent,
  ],
})
export class SharedUiUiModule {}

import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export function hasAtLeastOneFieldFilled(
  formGroup: UntypedFormGroup,
  includeCheckboxes = true
): boolean {
  for (const control of Object.values(formGroup.controls)) {
    const atLeastOneFieldFilled =
      control.dirty && control.value !== null && control.value !== '';
    if (
      (atLeastOneFieldFilled && includeCheckboxes) ||
      (atLeastOneFieldFilled &&
        !includeCheckboxes &&
        typeof control.value !== 'boolean')
    ) {
      return true;
    }
  }
  return false;
}

export function markGroupDirty(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof UntypedFormGroup) {
      markGroupDirty(control as UntypedFormGroup);
    } else if (control instanceof UntypedFormArray) {
      markArrayDirty(control as UntypedFormArray);
    } else if (control instanceof UntypedFormControl) {
      markControlDirty(control as UntypedFormControl);
    }
  });
}

export function markArrayDirty(formArray: UntypedFormArray) {
  Object.keys(formArray.controls).forEach((key) => {
    const control = formArray.get(key);
    if (control instanceof UntypedFormGroup) {
      markGroupDirty(control as UntypedFormGroup);
    } else if (control instanceof UntypedFormArray) {
      markArrayDirty(control as UntypedFormArray);
    } else if (control instanceof UntypedFormControl) {
      markControlDirty(control as UntypedFormControl);
    }
  });
}

export function markControlDirty(formControl: UntypedFormControl) {
  formControl.markAsDirty();
}

/**
 * Cleans the input value to ensure it contains only numbers and is at most 6 digits long.
 * @param value The input value to clean.
 * @returns A cleaned string containing only digits, limited to 6 characters.
 */
export function cleanExactSixDigits(value: string): string {
  return value.replace(/[^0-9]/g, '');
}

export class ReportHeader {
  readonly categories = ['new', 'upcoming', 'updated', 'recently_viewed'];
  id!: string;
  dateOfService!: string;
  reportDate!: string;
  orderingProviderName!: string;
  accountName!: string;
  isDetailAvailable!: boolean;
  hasMinimumDataForPdfRetrieval!: boolean;
  dashboardCategory!: string;
  weHealthEligible!: boolean;
  patientName!: string;
  pid!: number;
  orderedDate!: string;
  constructor(reportHeader: ReportHeader) {
    if (reportHeader) {
      this.id = reportHeader.id;
      this.dateOfService = reportHeader.dateOfService;
      this.reportDate = reportHeader.reportDate;
      this.orderingProviderName = reportHeader.orderingProviderName;
      this.accountName = reportHeader.accountName;
      this.isDetailAvailable = reportHeader.isDetailAvailable;
      this.hasMinimumDataForPdfRetrieval =
        reportHeader.hasMinimumDataForPdfRetrieval;
      this.dashboardCategory = reportHeader.dashboardCategory;
      this.weHealthEligible = reportHeader.weHealthEligible;
      this.patientName = reportHeader.patientName;
      this.pid = reportHeader.pid;
      this.orderedDate = reportHeader.orderedDate;
    }
  }

  get normalizedDashboardCategory(): string {
    return this.dashboardCategory.toLocaleLowerCase();
  }

  get linkEnabled(): boolean {
    return this.normalizedDashboardCategory !== this.categories[1];
  }

  get finalReportDateHeader(): string {
    switch (this.normalizedDashboardCategory) {
      case this.categories[0]:
      case this.categories[3]: {
        return 'Final Report Date';
      }

      case this.categories[1]: {
        return 'Available on:';
      }

      case this.categories[2]: {
        return 'Updated Final Report Date';
      }

      default: {
        return '';
      }
    }
  }

  get formattedDashboardCategory(): string {
    return this.dashboardCategory === this.categories[3]
      ? ''
      : this.dashboardCategory === this.categories[0]
      ? 'unread'
      : this.dashboardCategory;
  }
}

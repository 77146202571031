<ng-container *ngIf="showInstruction">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Confirm factor removal</h3>
      <div class="row ps-4 py-2">
        Reasons you may want to remove a factor include:
        <ul class="mt-4">
          <li>
            To prevent unauthorized access if your mobile device is lost/ stolen.
          </li>
          <li>You want to install the factor on a different device.</li>
        </ul>
        <p>Are you sure you want to remove the {{ getFactor(factor) }} factor?</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="removeUnenrollmentMFA" type="button" class="btn btn-secondary" (click)="cancel()">
      Cancel
    </button>
    <button id="removeFactorMFA" type="button" class="btn btn-primary" (click)="removeFactor()" [disabled]="isLoading">
      Remove Factor
    </button>
  </div>
</ng-container>
<ng-container *ngIf="showConfirmationScreen">
  <div class="modal-body pb-3">
    <div class="custom-modal-body" *ngIf="factor === 'SMS'">
      <h3>
        <span class="material-icons-round icon-lg text-success">check_circle_outline</span> You've removed your SMS authentication factor.
      </h3>
    </div>
    <div class="custom-modal-body" *ngIf="factor === 'GOOGLE'">
      <h3>
        <span class="material-icons-round icon-lg text-success">check_circle_outline</span> You've removed your Google Authenticator factor.
      </h3>
      <p>
        You should also remove your account from the app on your mobile device. Open the Google Authenticator app and tap the pencil icon.
        Select the account to edit, tap the trash can icon and then tap Remove account.
      </p>
    </div>
    <div class="custom-modal-body" *ngIf="factor === 'OKTA'">
      <h3><span class="material-icons-round icon-lg text-success">check_circle_outline</span> You've removed your Okta Verify factor.</h3>
      <p>
        You should also remove your account from the app on your mobile device. Open the Okta Verify app, tap Edit and the tap the red icon
        next to the account. Tap Delete.
      </p>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="confirmUnenrollmentMFA" type="button" class="btn btn-primary" (click)="closeModal()">Done</button>
  </div>
</ng-container>
<ng-container *ngIf="showLastFactorRemoval">
  <div class="modal-body pb-3">
    <div class="custom-modal-body">
      <h3>Confirm factor removal</h3>
      <div class="row ps-4 py-2">
        This is your final enrolled authentication method. Removing this factor will result in disabling MFA on your account.

        <p class="mt-4">Are you sure you want to unenroll from MFA?</p>
      </div>
    </div>
    <ui-legal-disclaimer
      [addClass]="'mt-3'"
      linkText="Unenroll MFA"
      termsUrl="https://www.labcorp.com/terms-and-conditions"
      privacyUrl="https://www.labcorp.com/hipaa-privacy"
    ></ui-legal-disclaimer>
  </div>
  <div class="modal-footer justify-content-end">
    <button id="cancelUnenrollmentMFA" type="button" class="btn btn-secondary" (click)="cancel()">
      Cancel
    </button>
    <button id="unenrollMFA" type="button" class="btn btn-primary" [disabled]="isLoading" (click)="confirmRemoveFactor(true)">
      Unenroll MFA
    </button>
  </div>
</ng-container>

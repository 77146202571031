import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SmartyStreetsActions } from './smartystreets.actions';
import { SmartyStreetsService } from './smartystreets.service';
@Injectable()
export class SmartyStreetsEffects {
  smartyStreetSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SmartyStreetsActions.smartyStreetsSearch),
      switchMap((action) =>
        this.smartyStreetsService.searchAddress(action.payload).pipe(
          map((smartyStreetsResult) => {
            if (smartyStreetsResult && smartyStreetsResult.suggestions) {
              return SmartyStreetsActions.smartyStreetsAddZipCode({
                payload: smartyStreetsResult,
              });
            } else {
              return SmartyStreetsActions.smartyStreetsSuggestResults({
                payload: [],
              });
            }
          }),
          catchError(() =>
            of(
              SmartyStreetsActions.smartyStreetsSuggestResults({ payload: [] })
            )
          )
        )
      )
    )
  );
  smartyStreetsAddZipCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SmartyStreetsActions.smartyStreetsAddZipCode),
      switchMap((action) =>
        this.smartyStreetsService
          .addZipCode(
            action.payload.suggestions.map((suggestion) => ({
              street: suggestion.street_line,
              city: suggestion.city,
              state: suggestion.state,
              zipCode: suggestion.zipcode,
            }))
          )
          .pipe(
            map((res) =>
              SmartyStreetsActions.smartyStreetsSuggestResults({ payload: res })
            ),
            catchError(() =>
              of(
                SmartyStreetsActions.smartyStreetsSuggestResults({
                  payload: [],
                })
              )
            )
          )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private smartyStreetsService: SmartyStreetsService
  ) {}
}

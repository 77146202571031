<div class="h-100 w-100">
  <form id="loginForm" [formGroup]="signInForm" class="mobile-top-margin w-100">
    <div *ngIf="proccessing$ | async; else signinFormTemplate" class="text-center">
      <div class="row">
        <div class="col-sm-12">
          <div class="spinner-border text-gray" role="status"></div>
          <p class="small text-gray">Authenticating...</p>
        </div>
      </div>
    </div>
    <ng-template #signinFormTemplate>
      <div class="form-group form-group-margin-bottom" [formGroup]="signInForm">
        <label for="emailAddress">Email</label>
        <input
          tabindex="1"
          type="email"
          class="form-control"
          id="emailAddress"
          [ngClass]="{
            'is-invalid': (signInForm.controls.emailAddress.invalid && signInForm.controls.emailAddress.dirty) || loginFailed
          }"
          formControlName="emailAddress"
        />
        <div class="error-holder error-email">
          <ui-form-field-msg id="emailAddressError" [validationMessages]="validationMessages.emailAddress" controlName="emailAddress">
          </ui-form-field-msg>
          <small
            class="form-text invalid-feedback"
            [ngClass]="loginFailed && signInForm.controls.emailAddress.dirty ? 'd-block d-flex' : 'd-none'"
          >
            <i class="material-icons-round icon-sm">error_outline</i>
            <span class="align-self-center ps-1" [innerHTML]="validationMessages.emailAddress.incorrectLogin"></span>
          </small>
        </div>
      </div>
      <div class="form-group mb-2" [formGroup]="signInForm">
        <div class="d-flex justify-content-between">
          <label for="emailPassword">Password</label>
          <a tabindex="4" routerLink="/account/password/reset">Forgot password?</a>
        </div>
        <input
          tabindex="2"
          type="password"
          class="form-control"
          id="emailPassword"
          [class.is-invalid]="signInForm.controls.emailPassword.invalid && signInForm.controls.emailPassword.dirty"
          formControlName="emailPassword"
        />
        <div class="error-holder">
          <ui-form-field-msg id="emailPasswordError" [validationMessages]="validationMessages.emailPassword" controlName="emailPassword">
          </ui-form-field-msg>
        </div>
      </div>
      <div class="form-group row" [formGroup]="signInForm">
        <div class="col-sm-12 col-md-5">
          <button id="login-button" class="btn btn-min-width btn-primary my-3 me-5 w-100" (click)="onSubmit()">
            Sign In
          </button>
        </div>
        <div class="col-sm-12 col-md-7">
          <button id="create-account-button" class="btn btn-outline-primary my-3 w-100" routerLink="/account/registration/">
            Create an Account
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</div>

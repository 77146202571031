import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgent {
  isAndroid: boolean;
  isIPad: boolean;
  isIPhone: boolean;
  isMobile: boolean;
  isMobileChrome: boolean;
  isSafari: boolean;
  isIOS: boolean;
  isWebkit: boolean;

  constructor() {
    this.isAndroid = /Android|Mobile/i.test(navigator.userAgent);
    this.isIPad = /iPad/i.test(navigator.userAgent);
    this.isIPhone = /iPhone/i.test(navigator.userAgent);
    this.isMobile = /(iPad|iPhone|iPod|Android|Mobile)/i.test(
      navigator.userAgent
    );
    this.isMobileChrome = /CriOS/i.test(navigator.userAgent);
    this.isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      navigator.vendor.indexOf('Apple') > -1;
    this.isIOS = /iPad|iPhone/i.test(navigator.userAgent);
    this.isWebkit =
      /\b(iPad|iPhone|iPod)\b/.test(navigator.userAgent) &&
      /WebKit/.test(navigator.userAgent) &&
      !/Edge/.test(navigator.userAgent) &&
      !this.isMSStream();
  }

  private isMSStream(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).MSStream !== undefined;
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { MFAService } from '@patient-ui/patient-web/store';
import { EventCode, IAuthenicateResponse } from '@patient-ui/shared/models';
import { EnvironmentService } from '@patient-ui/shared-ui/utils';

import { MFASharedService } from '../../../../../shared';

@Component({
  selector: 'patient-ui-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
  @Input()
  userEmail!: string;
  passwordConfirm!: UntypedFormGroup;
  invalidPassword = false;
  authURL!: string;
  isLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private mfaService: MFASharedService,
    private envService: EnvironmentService,
    private logMfaMetric: MFAService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.authURL = this.envService.oktaIssuer;
    if (this.authURL) {
      this.authURL = this.authURL.replace('oauth2/default', 'api/v1/authn');
    }
  }

  initForm() {
    this.passwordConfirm = this.fb.group({
      password: new UntypedFormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
    });
  }

  onSubmit() {
    this.isLoading = true;
    const password = this.passwordConfirm.controls['password'].value;

    return this.http
      .post(this.authURL, {
        username: this.userEmail,
        password: password,
      })
      .subscribe(
        (response: unknown) => {
          this.isLoading = false;
          const res = response as IAuthenicateResponse;
          if (res.status === 'SUCCESS') {
            this.mfaService.setPasswordConfirmation(false);
            this.invalidPassword = false;
            this.logMfaMetric.logMFAMetric(
              'Password verified successfully in password confirmation page.',
              EventCode.mfaSetup
            );
          }
        },
        (__error) => {
          this.isLoading = false;
          this.invalidPassword = true;
          this.mfaService.setPasswordConfirmation(true);
          this.logMfaMetric.logMFAMetric(
            'Password verification failed in password confirmation page.',
            EventCode.mfaSetup
          );
        }
      );
  }
}

<div class="w-100 h-100">
  <p class="m-0">
    Map Center /
    <i class="material-icons-round text-secondary">my_location</i>
    at position: {{ center.lat }}, {{ center.lng }}
  </p>
  <ul *ngIf="markers.length">
    <li *ngFor="let marker of markers; index as i">
      <ui-map-marker [color]="marker.color" (click)="markerClicked.emit(i)">{{ i + 1 }}</ui-map-marker>
      at position: {{ marker.position.lat }}, {{ marker.position.lng }}
    </li>
  </ul>
</div>

<div class="modal-body pb-3">
  <div class="custom-modal-body">
    <h3>
      <span class="material-icons-round icon-lg text-success">check_circle_outline</span> You've successfully enrolled {{ factorType }}
    </h3>
    <p>
      You can enroll another factor for additional protection.
    </p>
  </div>
</div>
<div class="modal-footer justify-content-end">
  <button id="confirmMFA" type="button" class="btn btn-primary" (click)="cancel()">Done</button>
</div>

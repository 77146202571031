<div class="modal-header">
  <h2 ngbAutofocus *ngIf="header === 'simple'" class="modal-title flex-fill text-center">
    {{ headerText }}
  </h2>
  <ng-content *ngIf="header === 'custom'" select=".custom-modal-header"></ng-content>
  <button *ngIf="closeIcon" type="button" class="btn btn-link close-icon" aria-label="Close" (click)="activeModal.dismiss('close-btn')">
    <i class="material-icons-round">close</i>
  </button>
</div>

<div class="modal-body">
  <ng-content select=".custom-modal-body"></ng-content>
</div>

<div
  *ngIf="footer === 'simple'"
  class="modal-footer"
  [ngClass]="{ 'justify-content-between': justifyFooterContent === 'between', 'justify-content-end': justifyFooterContent === 'end' }"
>
  <button type="button" class="btn btn-outline-primary ms-n1" *ngIf="footerDenyText !== ''" (click)="activeModal.dismiss(footerDenyFlag)">
    {{ footerDenyText }}
  </button>
  <button type="button" class="btn btn-primary" [disabled]="footerAffirmativeInProgressSpinner" (click)="onAffirmativeButtonClick()">
    <span *ngIf="footerAffirmativeInProgressSpinner" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
    {{ footerAffirmativeText }}
  </button>
</div>
<ng-content *ngIf="footer === 'custom'" select=".custom-modal-footer"></ng-content>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RequestStatus } from '@patient-ui/shared/enums';

import { MicrobiomeActions } from './microbiome.actions';
import { MicrobiomeService } from './microbiome.service';
@Injectable()
export class MicrobiomeEffects {
  getMicrobiomeSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrobiomeActions.getMicrobiomeSurvey),
      switchMap((action) =>
        this.microbiomeService.getMicrobiomeSurvey(action.payload).pipe(
          map((res) =>
            MicrobiomeActions.getMicrobiomeSurveySuccess({ payload: res })
          ),
          catchError((error) =>
            of(
              MicrobiomeActions.getMicrobiomeSurveyFailure({
                payload: {
                  status: RequestStatus.Failure,
                  error: error.error?.errorMessages?.[0]?.code || '',
                },
              })
            )
          )
        )
      )
    )
  );
  postMicrobiomeSurveyResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrobiomeActions.postMicrobiomeSurveyResponse),
      switchMap((action) =>
        this.microbiomeService
          .postMicrobiomeSurveyResponse(action.payload)
          .pipe(
            map(() =>
              MicrobiomeActions.postMicrobiomeSurveyResponseSuccess({
                payload: RequestStatus.Success,
              })
            ),
            catchError((error) =>
              of(
                MicrobiomeActions.postMicrobiomeSurveyResponseFailure({
                  payload: {
                    status: RequestStatus.Failure,
                    error: error.error?.errorMessages?.[0]?.code || '',
                  },
                })
              )
            )
          )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private microbiomeService: MicrobiomeService
  ) {}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
  isDevMode,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';

function getFormArray(size: number): UntypedFormArray {
  const arr = [];

  for (let i = 0; i < size; i++) {
    arr.push(new UntypedFormControl(''));
  }

  return new UntypedFormArray(arr);
}

@Component({
  selector: 'patient-ui-activate-form',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ActivateFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ActivateFormComponent,
      multi: true,
    },
  ],
  templateUrl: './activate-form.component.html',
  styleUrls: ['./activate-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateFormComponent implements ControlValueAccessor, Validator {
  @Input()
  invalidInput!: boolean;
  @Input()
  set size(size: number) {
    this.inputs = getFormArray(size);
    this.size1 = size;
  }

  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;

  size1 = 6;
  scheduledFocus: number | null = 0;

  inputs = getFormArray(this.size1);

  onChange?: (value: string) => void;
  onTouched?: () => void;
  writeValue(value: string): void {
    if (isDevMode() && value?.length) {
      throw new Error('Otp input is not supposed to be prefilled with data');
    }
    this.onChange?.(value);
    this.inputs.setValue(new Array(this.size1).fill(''));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputs.disable();
    } else {
      this.inputs.enable();
    }
  }

  validate(control: any): ValidationErrors | null {
    if (!control.value || control.value.length < this.size1) {
      return {
        otpInput: 'Value is incorrect',
      };
    }

    return null;
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (idx > 0) {
        this.scheduledFocus = idx - 1;
      }
    }
  }

  handleInput() {
    setTimeout(() => {
      const firstInputValue = this.inputEls.first.nativeElement.value;
      if (
        firstInputValue.length > 1 &&
        firstInputValue.length === this.inputs.length
      ) {
        const digits = firstInputValue.split('');
        digits.forEach((digit, index) => {
          if (index < this.inputs.length) {
            const control = this.inputs.at(index);
            control.setValue(digit);
          }
        });
        if (digits.length === this.inputs.length) {
          this.inputEls.first.nativeElement.value = digits[0];
        }
        if (this.onChange) {
          this.onChange(digits.join(''));
        }
        this.focusInput(digits.length - 1);
      } else if (firstInputValue.length === 1) {
        this.updateWiredValue();
        if (this.scheduledFocus != null) {
          this.focusInput(this.scheduledFocus);
          this.scheduledFocus = null;
        }
      }
    }, 0);
  }

  handleKeyPress(e: KeyboardEvent, idx: number) {
    const isDigit = /\d/.test(e.key);

    if (e.key === 'v' && e.metaKey) {
      return true;
    }

    if (isDigit && idx + 1 < this.size1) {
      this.scheduledFocus = idx + 1;
    }

    if (isDigit && this.inputs.controls[idx].value) {
      this.inputs.controls[idx].setValue('');
    }
    return isDigit;
  }

  handlePaste(e: ClipboardEvent, idx: number) {
    e.preventDefault();

    if (idx !== 0) {
      return;
    }

    const pasteData = e.clipboardData?.getData('text');
    const regex = new RegExp(`\\d{${this.size1}}`);

    if (!pasteData || !regex.test(pasteData)) {
      return;
    }

    for (let i = 0; i < pasteData.length; i++) {
      this.inputs.controls[i].setValue(pasteData[i]);
    }

    this.focusInput(this.inputEls.length - 1);
    this.updateWiredValue();
    if (this.onTouched) {
      this.onTouched();
    }
  }

  handleFocus(e: FocusEvent) {
    (e.target as HTMLInputElement).select();
  }

  focusInput(idx: number) {
    setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
  }

  updateWiredValue() {
    setTimeout(() => this.onChange?.(this.inputs.value.join('')));
  }
}

import { IEnrichment } from './IEnrichment.interface';
import { TestResultComment } from './TestResultComment.model';

export class TestResult {
  readonly alertArray: string[] = ['HH', '*H', 'LL', '*L'];
  readonly abArray: string[] = ['H', 'L', 'A'];
  readonly criArray: string[] = ['>', '<', 'H*', 'L*', '+', '-', 'AA', 'C'];
  number!: string;
  name!: string;
  value!: string;
  abnormalIndicator!: string;
  referenceRange!: string;
  units!: string;
  status!: string;
  comments: TestResultComment[] = [];
  enrichment: IEnrichment;

  constructor(testResult: TestResult) {
    this.number = testResult.number;
    this.name = testResult.name;
    this.value = testResult.value;
    this.abnormalIndicator = testResult.abnormalIndicator;
    this.referenceRange = testResult.referenceRange;
    this.units = testResult.units;
    this.status = testResult.status;
    this.comments = testResult.comments;
    this.enrichment = testResult.enrichment;
  }

  get formattedName(): string {
    const value = this.name;

    return this.status === 'cancelled' ? value.concat(': CANCELLED') : value;
  }

  get formattedValue(): string {
    if (this.abnormalIndicator === 'T') {
      return 'Test Not Performed';
    } else if (this.abnormalIndicator === 'Q') {
      return 'Quantity Not Sufficient';
    }
    return this.value;
  }

  // note: as more placeholder test name phrases are identified, they will need to be added
  get displayNote(): boolean {
    return (
      this.name.toLowerCase().startsWith('please note:') ||
      this.name.toLowerCase().startsWith('comment:') ||
      this.name.toLowerCase().startsWith('disclaimer') ||
      this.name.toLowerCase().startsWith('interpretation') ||
      this.name.toLowerCase().startsWith('note:')
    );
  }

  get displayFormattedTextValue(): boolean {
    return !this.valueIsNumeric && this.formattedValue !== '';
  }

  get valueIsNumeric(): boolean {
    if (this.value) {
      // test for any carriage returns or newlines
      // these types of results need to be displayed as text
      const crRegEx = /[\r\n]/g;

      // test for numeric value
      const valueRegEx = /^[0-9]\d*(\.\d+)?$/g;

      // value should not contain new lines or returns and be numeric
      return !crRegEx.test(this.value) && valueRegEx.test(this.value);
    }
    return false;
  }

  get displayCommentsInFigCaption(): boolean {
    return this.comments.length > 0;
  }

  get rangeLowValue(): string {
    let lowValue = '-1';
    if (this.referenceRange !== null) {
      if (this.referenceRange.indexOf('-') > -1) {
        lowValue = this.referenceRange.split('-')[0];
      }
    }
    return lowValue;
  }

  get rangeHighValue(): string {
    let highValue = '-1';
    if (this.referenceRange !== null) {
      if (this.referenceRange.indexOf('-') > -1) {
        highValue = this.referenceRange.split('-')[1];
      }
    }
    return highValue;
  }

  get validNumericRange(): boolean {
    if (!this.showReferenceRange) {
      return false;
    }
    if (this.rangeHighValue !== '-1' && this.rangeLowValue !== '-1') {
      return true;
    }
    return false;
  }

  get displayGreyBar(): boolean {
    if (!this.showReferenceRange) {
      return true;
    }
    if (!this.valueIsNumeric) {
      return true;
    }
    return false;
  }
  get displayGreenBar(): boolean {
    if (this.wordyValue) {
      return false;
    }
    if (!this.valueIsNumeric) {
      return false;
    }
    if (!this.validNumericRange) {
      return false;
    }
    return true;
  }

  get showReferenceRange(): boolean {
    if (this.referenceRange === null) {
      return false;
    }
    return true;
  }

  get wordyValue(): boolean {
    if (this.value.length > 20) {
      return true;
    }
    if (this.displayNote) {
      return true;
    }
    return false;
  }

  get isNumberAndNumericRange(): boolean {
    if (!this.valueIsNumeric) {
      return false;
    }
    if (!this.validNumericRange) {
      return false;
    }
    return true;
  }

  get valueIsNumericAndValidRange(): boolean {
    if (this.valueIsNumeric) {
      if (this.showReferenceRange) {
        return true;
      }
    }
    return false;
  }

  get iconShapeClass(): string {
    if (this.abnormalIndicator === 'H' || this.abnormalIndicator === 'A') {
      return 'triangle-up';
    } else if (this.abnormalIndicator === 'L') {
      return 'triangle-down';
    } else if (
      this.alertArray.indexOf(this.abnormalIndicator) > -1 ||
      this.criArray.indexOf(this.abnormalIndicator) > -1
    ) {
      return 'square';
    }
    return '';
  }

  get textClass(): string {
    if (this.abnormalText === 'Critical' || this.abnormalText === 'Alert') {
      return 'text-bold-red';
    } else if (this.abnormalText.length > 0) {
      return 'font-weighted';
    }
    return '';
  }

  get abnormalText(): string {
    if (this.abnormalIndicator === 'H') {
      return 'High';
    } else if (this.abnormalIndicator === 'L') {
      return 'Low';
    } else if (this.alertArray.indexOf(this.abnormalIndicator) > -1) {
      return 'Alert';
    } else if (this.criArray.indexOf(this.abnormalIndicator) > -1) {
      return 'Critical';
    } else if (this.abnormalIndicator === 'A') {
      return 'Abnormal';
    }

    return '';
  }
}

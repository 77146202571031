import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroupName,
  UntypedFormGroup,
} from '@angular/forms';

import { getControl } from '../form-control-helper.utils';

export interface CheckboxGroupComponentItem {
  formControlName: string;
  domId: string;
  label: string;
}

@Component({
  selector: 'ui-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: [FormGroupDirective, FormGroupName],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent implements OnInit {
  @HostBinding('class.d-block') isBlock = true;

  @Input() group!: UntypedFormGroup;
  @Input() groupName = '';

  @Input() label = '';
  @Input() items: CheckboxGroupComponentItem[] = [];

  constructor(
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private parentGroupName: FormGroupName
  ) {}

  ngOnInit(): void {
    if (!this.group) {
      this.group = getControl(
        'ui-checkbox-group',
        this.parentForm,
        this.parentGroupName,
        this.groupName
      ) as UntypedFormGroup;
    }
  }
}
